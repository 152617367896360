import { ILog } from "@ihr-radioedit/inferno-core";

import { CcpaStatus } from "../../abstracts/store/base-store.abstract";
import type { Store } from "../../stores";
import { AmazonBidding } from "./amazon";
import { RubiconBidding } from "./rubicon";

const log = ILog.logger("BiddingManager/bidding/index.ts");

export interface BiddingProviderArgs {
  store: Store;
  timeout: number;
  googletag: googletag.Googletag;
}

export interface BiddingProviderSlot {
  slot: googletag.Slot;
  sizes: number[][] | string;
}

export interface BiddingProvider {
  loadLibrary: () => Promise<void>;
  fetch: (slots?: BiddingProviderSlot[]) => Promise<any>;
}

export const BIDDING_PROVIDERS = [AmazonBidding, RubiconBidding];
export const BIDDING_TIMEOUT = 2000;

export class BiddingManager {
  private enabled = false;
  private providers: BiddingProvider[] = [];

  constructor(private store: Store) {}
  queue = (provider: BiddingProvider) => {
    if (provider) {
      this.providers.push(provider);
    }
  };

  refresh = (slots: BiddingProviderSlot[]): Promise<boolean[] | string> => {
    this.enabled =
      (this.store?.getCcpaStatus() !== CcpaStatus.OptOut && this.store?.site.sections.ads?.header_bidding) || false;

    if (!this.enabled) {
      const message = "Header bidding disabled";
      log.debug(message);
      return Promise.resolve(message);
    }

    log.debug("Refreshing header bidding");

    return Promise.all(this.providers.map(provider => provider.fetch(slots)));
  };
}
