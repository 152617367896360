export class Deferred<T = any, U = any> {
  promise: Promise<T>;
  resolve: (v: T) => void = () => null;
  reject: (v?: U) => void = () => null;

  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  get then() {
    return this.promise.then.bind(this.promise);
  }

  get catch() {
    return this.promise.catch.bind(this.promise);
  }
}
