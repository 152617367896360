import { ILog } from "@ihr-radioedit/inferno-core";
import { Deferred } from "@ihr-radioedit/inferno-core";

import { promiseTimeout } from "../../utilities/promise-timeout";
import { BiddingProvider, BiddingProviderArgs, BiddingProviderSlot } from "./index";

const log = ILog.logger("RubiconBidding");

declare global {
  interface Window {
    pbjs: {
      rp: {
        requestBids: (opts: { gptSlotObjects: googletag.Slot[]; callback: () => void }) => void;
      };
    };
  }
}

export class RubiconBidding implements BiddingProvider {
  private _library?: Promise<void>;
  private libId = "rubicon-lib";
  private pendingFetchPromise: Deferred<boolean> | null = null;

  constructor(private opts: BiddingProviderArgs) {}

  loadLibrary() {
    if (this._library) {
      return this._library;
    }

    const { env } = this.opts.store;
    this._library = new Promise<void>((resolve, reject) => {
      const head = document.querySelector("head");
      const script = document.createElement("script");
      if (head && env.RUBICON_BIDDING_LIBRARY) {
        script.id = this.libId;
        script.async = true;
        script.defer = true;
        script.src = env.RUBICON_BIDDING_LIBRARY;
        script.onload = () => {
          log.debug("Rubicon Bidding library loaded");
          resolve();
        };

        script.onerror = (
          event: Event | string,
          source?: string,
          fileno?: number,
          columnNumber?: number,
          error?: Error,
        ) => {
          if (error) {
            log.debug("Rubicon Bidding library could not be loaded", error.message);
          }
          reject(new Error("Rubicon Bidding library could not be loaded"));
        };

        head.appendChild(script);
      } else {
        log.debug(
          `Rubicon Bidding library could not be loaded: head? ${!!head} configured? ${env.RUBICON_BIDDING_LIBRARY}`,
        );
        reject(new Error("Rubicon Bidding library could not be loaded"));
      }
    });

    return this._library;
  }

  async fetch(slots?: BiddingProviderSlot[]) {
    const gptSlots: googletag.Slot[] = slots?.map(item => item.slot) || [];

    return promiseTimeout(
      this.opts.timeout,
      this.loadLibrary()
        .then(async () => {
          if (!gptSlots?.length) {
            return Promise.reject(false);
          }

          if (!this.pendingFetchPromise) {
            this.pendingFetchPromise = new Deferred<boolean>();
          }

          window.pbjs.rp.requestBids({
            gptSlotObjects: gptSlots,
            callback: () => {
              this.pendingFetchPromise?.resolve(true);
            },
          });

          return this.pendingFetchPromise.promise;
        })
        .catch((e: Error) => {
          log.debug(e.message);
          return Promise.reject(false);
        }),
    );
  }
}
